/* tslint:disable */
/* eslint-disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ## A custom template from the reclaim-api/api-client         ##
 * ## repo was used.                                            ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";

export interface CalendarEvent {
  title?: string;
  public?: boolean;
  reclaimManaged?: boolean;
  eventId?: string;
  startTime?: string;
  endTime?: string;
  color?: EventColor;
  displayColorHex?: string;
  onlineMeetingUrl?: string | null;
}

export enum ConferenceType {
  GOOGLE_MEET = "GOOGLE_MEET",
  ZOOM = "ZOOM",
  CUSTOM = "CUSTOM",
}

export interface CreateSchedulingLinkRequest {
  title: string;
  slug?: string | null;
  description?: string | null;
  enabled: boolean;
  timePolicyType: TimePolicyType;
  oneOffPolicy?: TimePolicy & any;
  durations: number[];
  daysIntoFuture?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  priority: SchedulingPriority;
  conferenceType?: ConferenceType & any;
  iconType: IconType;
  location?: string | null;
}

export interface DayHours {
  intervals: LocalTimeInterval[];
  endOfDay?: LocalTime;
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export interface Duration {
  units?: TemporalUnit[];
  zero?: boolean;
  negative?: boolean;
  seconds?: number;
  nano?: number;
}

export enum EventColor {
  NONE = "NONE",
  LAVENDER = "LAVENDER",
  SAGE = "SAGE",
  GRAPE = "GRAPE",
  FLAMINGO = "FLAMINGO",
  BANANA = "BANANA",
  TANGERINE = "TANGERINE",
  PEACOCK = "PEACOCK",
  GRAPHITE = "GRAPHITE",
  BLUEBERRY = "BLUEBERRY",
  BASIL = "BASIL",
  TOMATO = "TOMATO",
}

export enum IconType {
  LIGHT = "LIGHT",
  COFFEE = "COFFEE",
  BOLT = "BOLT",
  COMMENT = "COMMENT",
  STAR = "STAR",
  AIRPLANE = "AIRPLANE",
  TWINKLE = "TWINKLE",
  COMPASS = "COMPASS",
  SUN = "SUN",
  SMILE = "SMILE",
}

export type LocalTime = string;

export interface LocalTimeInterval {
  start: LocalTime;
  end: LocalTime;
  duration?: Duration;
}

export type Object = object;

export interface PartialSchedulingLinkMeeting {
  start?: string | null;
  end?: string | null;
  inviteeName?: string | null;
  inviteeEmail?: string | null;
  ccEmails?: string[] | null | null;
  message?: string | null;
  changeMessage?: string | null;
}

export interface SchedulingLink {
  id: string;
  title: string;
  slug: string;
  description?: string | null;
  enabled: boolean;
  organizer: ThinPerson;
  timePolicyType: TimePolicyType;
  oneOffPolicy?: TimePolicy & any;
  durations: number[];
  daysIntoFuture?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  priority: SchedulingPriority;
  conferenceType?: ConferenceType & any;
  iconType: IconType;
  location?: string | null;
  organizerRefCode?: string | null;
}

export interface SchedulingLinkMeetingAvailability {
  inviteeEvents?: CalendarEvent[] | null | null;
  availableTimes: Record<string, SchedulingLinkMeetingAvailabilitySlot[]>;
}

export interface SchedulingLinkMeetingAvailabilitySlot {
  startTime: string;
  endTime: string;
  isSuggested: boolean;
  suggested?: boolean;
}

export interface SchedulingLinkMeetingEvent {
  meetingId: string;
  event: CalendarEvent;
  schedulingLinkId: string;
  organizer: ThinPerson;
  attendee: ThinPerson;
  conferenceData?: object | null;
}

export interface SchedulingLinkMeetingRequest {
  start: string;
  end: string;
  inviteeName: string;
  inviteeEmail: string;
  ccEmails?: string[] | null | null;
  message?: string | null;
}

export enum SchedulingPriority {
  DEFAULT = "DEFAULT",
  HIGH = "HIGH",
}

export interface TemporalUnit {
  duration?: Duration;
  durationEstimated?: boolean;
  dateBased?: boolean;
  timeBased?: boolean;
}

export interface ThinPerson {
  userId?: string | null;
  email: string;
  name?: string;
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
}

export interface TimePolicy {
  startOfWeek?: DayOfWeek;
  endOfWeek?: DayOfWeek;
  dayHours: Record<string, DayHours>;
}

export enum TimePolicyType {
  WORK = "WORK",
  PERSONAL = "PERSONAL",
  MEETING = "MEETING",
  ONE_OFF = "ONE_OFF",
}

export interface UserSlug {
  slug: string;
}

export namespace schedulingLink {
  /**
   * @tags scheduling-link
   * @name getAllLinks
   * @request GET:/scheduling-link
   */
  export namespace GetAllLinks {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = SchedulingLink[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name createLink
   * @request POST:/scheduling-link
   */
  export namespace CreateLink {
    export type RequestQuery = {};
    export type RequestBody = CreateSchedulingLinkRequest;
    export type ResponseBody = SchedulingLink;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getLinkForUserAndLinkSlug
   * @request GET:/scheduling-link/for-user-link-slug/{userSlug}/{linkSlug}
   */
  export namespace GetLinkForUserAndLinkSlug {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = SchedulingLink;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getAllLinksForUserSlug
   * @request GET:/scheduling-link/for-user-slug/{userSlug}
   */
  export namespace GetAllLinksForUserSlug {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = SchedulingLink[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name findMeeting
   * @request GET:/scheduling-link/meeting/{meetingId}
   */
  export namespace FindMeeting {
    export type RequestQuery = { zoneId?: string | null };
    export type RequestBody = never;
    export type ResponseBody = SchedulingLinkMeetingEvent;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name deleteMeeting
   * @request DELETE:/scheduling-link/meeting/{meetingId}
   */
  export namespace DeleteMeeting {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name updateMeeting
   * @request PATCH:/scheduling-link/meeting/{meetingId}
   */
  export namespace UpdateMeeting {
    export type RequestQuery = {};
    export type RequestBody = PartialSchedulingLinkMeeting;
    export type ResponseBody = SchedulingLinkMeetingEvent;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name schedulingLinkSlugExists
   * @request GET:/scheduling-link/slug-exists
   */
  export namespace SchedulingLinkSlugExists {
    export type RequestQuery = { slug: string };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getMyUserSlug
   * @request GET:/scheduling-link/user-slug
   */
  export namespace GetMyUserSlug {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = UserSlug;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name updateMyUserSlug
   * @request POST:/scheduling-link/user-slug
   */
  export namespace UpdateMyUserSlug {
    export type RequestQuery = {};
    export type RequestBody = UserSlug;
    export type ResponseBody = UserSlug;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name userSlugExists
   * @request GET:/scheduling-link/user-slug/exists
   */
  export namespace UserSlugExists {
    export type RequestQuery = { slug: string };
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getUserSlug
   * @request GET:/scheduling-link/user-slug/{userId}
   */
  export namespace GetUserSlug {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = UserSlug;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getLink
   * @request GET:/scheduling-link/{id}
   */
  export namespace GetLink {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = SchedulingLink;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name deleteLink
   * @request DELETE:/scheduling-link/{id}
   */
  export namespace DeleteLink {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = Object;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name updateLink
   * @request PATCH:/scheduling-link/{id}
   */
  export namespace UpdateLink {
    export type RequestQuery = {};
    export type RequestBody = Object;
    export type ResponseBody = SchedulingLink;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getAvailabilityForDates
   * @request POST:/scheduling-link/{schedulingLinkId}/availability
   */
  export namespace GetAvailabilityForDates {
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type ResponseBody = Record<string, boolean>;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getAvailabilityForDates_1
   * @request GET:/scheduling-link/{schedulingLinkId}/availability/V2
   */
  export namespace GetAvailabilityForDates1 {
    export type RequestQuery = { zoneId: string; startDate: string; endDate: string };
    export type RequestBody = never;
    export type ResponseBody = Record<string, boolean>;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name createMeeting
   * @request POST:/scheduling-link/{schedulingLinkId}/meeting
   */
  export namespace CreateMeeting {
    export type RequestQuery = {};
    export type RequestBody = SchedulingLinkMeetingRequest;
    export type ResponseBody = SchedulingLinkMeetingEvent;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getMeetingSlots
   * @request GET:/scheduling-link/{schedulingLinkId}/meeting/availability
   */
  export namespace GetMeetingSlots {
    export type RequestQuery = { requestDate: string };
    export type RequestBody = never;
    export type ResponseBody = SchedulingLinkMeetingAvailability;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name getMeetingSlots_1
   * @request GET:/scheduling-link/{schedulingLinkId}/meeting/availability/V2
   */
  export namespace GetMeetingSlots1 {
    export type RequestQuery = { zoneId: string; date: string };
    export type RequestBody = never;
    export type ResponseBody = SchedulingLinkMeetingAvailability;
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }

  /**
   * @tags scheduling-link
   * @name listMeetingsForSchedulingLink
   * @request GET:/scheduling-link/{schedulingLinkId}/meetings
   */
  export namespace ListMeetingsForSchedulingLink {
    export type RequestQuery = {};
    export type RequestBody = never;
    export type ResponseBody = SchedulingLinkMeetingEvent[];
    export type ErrorType = { status: 401; response: unknown } | { status: 500; response: unknown };
  }
}

export const HEADER_BUILD_ID = "X-Reclaim-Build-Id";

export type RequestParams = Omit<AxiosRequestConfig, "url" | "data" | "method"> & {
  secure?: boolean;
};

// TODO: The need for this is hard codded into the codegen source... so dumb
enum BodyType {
  Json,
}

export type RequestQueryParamsType = Record<string | number, any>;

export type ApiConfig<SecurityDataType> = {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
};

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
export type TypedErrorPromise<ResolveType, RejectType = unknown> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): Promise<ResolveType | TResult>;
  finally<TResult = never>(onfinally?: (() => void) | undefined | null): Promise<ResolveType | TResult>;
  readonly [Symbol.toStringTag]: string;
};

export class ReclaimApiError<
  E extends { status: number; response?: unknown | null } = { status: number; response?: unknown | null },
> extends Error {
  status: E["status"];
  statusText?: string;
  headers: unknown;
  body: E["response"];
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.status = res?.status;
    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

export class NetworkError extends Error {
  status: "NetworkError" = "NetworkError";
  statusText: any;
  headers: any;
  body: any;
  handled: boolean;

  constructor(res: AxiosResponse, message?: string) {
    super(message);

    if (!res && !message) message = "No response object";

    this.statusText = res?.statusText || res.data?.message || message;
    this.headers = res?.headers;
    this.body = res?.data;
  }
}

// export type ClientConfig = ApiConfig<SecurityDataType,> & {BUILD_ID?: string}
export type ClientConfig = ApiConfig<any> & { BUILD_ID?: string };

class HttpClient<SecurityDataType> {
  public baseUrl: string = "https://api.app.reclaim.ai/api";
  public client: AxiosInstance;
  private securityData: SecurityDataType = null as any;
  private securityWorker: ApiConfig<SecurityDataType>["securityWorker"] = (() => {}) as any;
  private baseApiParams: RequestParams = {
    headers: {
      "Content-Type": "application/json",
      Referrer: "no-referrer",
    },
  };

  private requestHooks = {};
  private responseHooks = {};

  constructor({ baseUrl, baseApiParams, securityWorker, BUILD_ID }: ClientConfig = { BUILD_ID: "unknown" }) {
    if (!!baseUrl) this.baseUrl = baseUrl;
    if (!!baseApiParams) this.baseApiParams = baseApiParams;
    if (!!securityWorker) this.securityWorker = securityWorker;
    this.client = axios.create({
      baseURL: baseUrl || this.baseUrl,
      withCredentials: true,
      headers: {
        [HEADER_BUILD_ID]: BUILD_ID,
        ...(this.baseApiParams.headers || {}),
        post: {
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    });
    this.client.interceptors.request.use((config) => {
      Object.keys(this.requestHooks).forEach((name) => {
        this.requestHooks[name](config);
      });

      return config;
    });

    this.client.interceptors.response.use(
      (r) => {
        Object.keys(this.responseHooks).forEach((name) => {
          if (r.status) {
            return this.responseHooks[`${name} - Status: ${r.status}`](new ReclaimApiError(r));
          }

          this.responseHooks[`${name} - Status: network`](new NetworkError(r));
        });
        return r;
      },
      (error) => {
        Object.keys(this.responseHooks).forEach((name) => {
          this.responseHooks[name](error);
        });
        return error;
      },
    );
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  public registerRequestHook(name: string, fn: (config: AxiosRequestConfig) => void | Promise<void>) {
    this.requestHooks[name] = fn;
  }

  public registerResponseHook(
    name: string,
    status: 401 | 500 | "NetworkError",
    fn: (error: any) => void | Promise<void>,
  ) {
    this.responseHooks[`${name} - Status: ${status}`] = fn;
  }

  public request = <T extends unknown, E extends { status: number; response?: unknown | null }>(
    path: string,
    method: AxiosRequestConfig["method"],
    { secure, ...params }: RequestParams = {},
    data?: any,
    _dumbUnusedBodyType?: any,
    secureByDefault?: boolean,
  ): TypedErrorPromise<T, ReclaimApiError<E> | NetworkError> =>
    this.client
      .request({
        url: path,
        ...params,
        method,
        data,
        validateStatus: function (status) {
          return true;
        },
      })
      .then((r) => {
        if (r.status >= 200 && r.status < 300) {
          return !!r.data ? r.data : null;
        } else if (!r.status) {
          throw new NetworkError(r);
        } else {
          throw new ReclaimApiError(r);
        }
      });
}

/**
 * @title Reclaim Scheduling Links API
 * @version 0.1
 * @baseUrl https://api.app.reclaim.ai/api
 * Reclaim's awesome scheduling links API
 */
export class ReclaimApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  schedulingLink = {
    /**
     * @tags scheduling-link
     * @name getAllLinks
     * @request GET:/scheduling-link
     */
    getAllLinks: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<
        SchedulingLink[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link`, "GET", params),

    /**
     * @tags scheduling-link
     * @name createLink
     * @request POST:/scheduling-link
     */
    createLink: <ErrorTypes extends { status: number; response: any }>(
      data: CreateSchedulingLinkRequest,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLink,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link`, "POST", params, data),

    /**
     * @tags scheduling-link
     * @name getLinkForUserAndLinkSlug
     * @request GET:/scheduling-link/for-user-link-slug/{userSlug}/{linkSlug}
     */
    getLinkForUserAndLinkSlug: <ErrorTypes extends { status: number; response: any }>(
      userSlug: string,
      linkSlug: string,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLink,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/for-user-link-slug/${userSlug}/${linkSlug}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name getAllLinksForUserSlug
     * @request GET:/scheduling-link/for-user-slug/{userSlug}
     */
    getAllLinksForUserSlug: <ErrorTypes extends { status: number; response: any }>(
      userSlug: string,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLink[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/for-user-slug/${userSlug}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name findMeeting
     * @request GET:/scheduling-link/meeting/{meetingId}
     */
    findMeeting: <ErrorTypes extends { status: number; response: any }>(
      meetingId: string,
      query?: { zoneId?: string | null },
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingEvent,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/meeting/${meetingId}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name deleteMeeting
     * @request DELETE:/scheduling-link/meeting/{meetingId}
     */
    deleteMeeting: <ErrorTypes extends { status: number; response: any }>(meetingId: string, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/meeting/${meetingId}`,
        "DELETE",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name updateMeeting
     * @request PATCH:/scheduling-link/meeting/{meetingId}
     */
    updateMeeting: <ErrorTypes extends { status: number; response: any }>(
      meetingId: string,
      data: PartialSchedulingLinkMeeting,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingEvent,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/meeting/${meetingId}`, "PATCH", params, data),

    /**
     * @tags scheduling-link
     * @name schedulingLinkSlugExists
     * @request GET:/scheduling-link/slug-exists
     */
    schedulingLinkSlugExists: <ErrorTypes extends { status: number; response: any }>(
      query: { slug: string },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/slug-exists${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name getMyUserSlug
     * @request GET:/scheduling-link/user-slug
     */
    getMyUserSlug: <ErrorTypes extends { status: number; response: any }>(params?: RequestParams) =>
      this.request<UserSlug, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/user-slug`,
        "GET",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name updateMyUserSlug
     * @request POST:/scheduling-link/user-slug
     */
    updateMyUserSlug: <ErrorTypes extends { status: number; response: any }>(data: UserSlug, params?: RequestParams) =>
      this.request<UserSlug, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/user-slug`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags scheduling-link
     * @name userSlugExists
     * @request GET:/scheduling-link/user-slug/exists
     */
    userSlugExists: <ErrorTypes extends { status: number; response: any }>(
      query: { slug: string },
      params?: RequestParams,
    ) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/user-slug/exists${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name getUserSlug
     * @request GET:/scheduling-link/user-slug/{userId}
     */
    getUserSlug: <ErrorTypes extends { status: number; response: any }>(userId: string, params?: RequestParams) =>
      this.request<UserSlug, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/user-slug/${userId}`,
        "GET",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name getLink
     * @request GET:/scheduling-link/{id}
     */
    getLink: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<
        SchedulingLink,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${id}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name deleteLink
     * @request DELETE:/scheduling-link/{id}
     */
    deleteLink: <ErrorTypes extends { status: number; response: any }>(id: string, params?: RequestParams) =>
      this.request<Object, { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes>(
        `/scheduling-link/${id}`,
        "DELETE",
        params,
      ),

    /**
     * @tags scheduling-link
     * @name updateLink
     * @request PATCH:/scheduling-link/{id}
     */
    updateLink: <ErrorTypes extends { status: number; response: any }>(
      id: string,
      data: Object,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLink,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${id}`, "PATCH", params, data),

    /**
     * @tags scheduling-link
     * @name getAvailabilityForDates
     * @request POST:/scheduling-link/{schedulingLinkId}/availability
     */
    getAvailabilityForDates: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      data: string[],
      params?: RequestParams,
    ) =>
      this.request<
        Record<string, boolean>,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/availability`, "POST", params, data),

    /**
     * @tags scheduling-link
     * @name getAvailabilityForDates_1
     * @request GET:/scheduling-link/{schedulingLinkId}/availability/V2
     */
    getAvailabilityForDates1: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      query: { zoneId: string; startDate: string; endDate: string },
      params?: RequestParams,
    ) =>
      this.request<
        Record<string, boolean>,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/availability/V2${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name createMeeting
     * @request POST:/scheduling-link/{schedulingLinkId}/meeting
     */
    createMeeting: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      data: SchedulingLinkMeetingRequest,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingEvent,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/meeting`, "POST", params, data),

    /**
     * @tags scheduling-link
     * @name getMeetingSlots
     * @request GET:/scheduling-link/{schedulingLinkId}/meeting/availability
     */
    getMeetingSlots: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      query: { requestDate: string },
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingAvailability,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/meeting/availability${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name getMeetingSlots_1
     * @request GET:/scheduling-link/{schedulingLinkId}/meeting/availability/V2
     */
    getMeetingSlots1: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      query: { zoneId: string; date: string },
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingAvailability,
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/meeting/availability/V2${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags scheduling-link
     * @name listMeetingsForSchedulingLink
     * @request GET:/scheduling-link/{schedulingLinkId}/meetings
     */
    listMeetingsForSchedulingLink: <ErrorTypes extends { status: number; response: any }>(
      schedulingLinkId: string,
      params?: RequestParams,
    ) =>
      this.request<
        SchedulingLinkMeetingEvent[],
        { status: 401; response: unknown } | { status: 500; response: unknown } | ErrorTypes
      >(`/scheduling-link/${schedulingLinkId}/meetings`, "GET", params),
  };
}
